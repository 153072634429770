import { Modal } from 'antd';
import { intl } from 'di18n-react';
import './index.less';
import MemberListOfResource from './MemberListOfResource';
import RestrictLength from '@/components/RestrictLength';

const CooperFoldAuth = ({
  resourceId,
  teamId,
  folderName,
  onCancel,
  refreshFileOrCrumb, // 更新文件列表
  goRootFolder,
}) => {
  const closeFoldAuthModal = () => {
    onCancel();
    refreshFileOrCrumb();
  };

  return <Modal
    closable={true}
    wrapClassName={'cooperFoldAuth-modal'}
    visible={true}
    style={{ top: 100 }}
    footer={null}
    width={570}
    maskClosable={false}
    onCancel={closeFoldAuthModal}
    title={<span><RestrictLength
      word={folderName}
      maxLength={18} />&nbsp;{intl.t('权限设置')}</span>}
    >
    <MemberListOfResource
      refreshFileOrCrumb={refreshFileOrCrumb}
      resourceId={resourceId}
      teamId={teamId}
      folderName={folderName}
      goRootFolder={goRootFolder}
      closeFoldAuthModal={closeFoldAuthModal}
      resourceType={'dir'} // 代表文件夹成员列表
    />
  </Modal>
};


export default CooperFoldAuth;
