/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-07-22 15:41:23
 * @LastEditTime: 2024-04-12 17:20:49
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/CooperFoldAuth/addMember/memberItem/index.js
 *
 */
import React, { useContext } from 'react';
import { intl } from 'di18n-react';
import classBind from 'classnames/bind';
import { beautySub, highlight } from '@/utils';
import DepartContent from '../../departContent';
import UserPermChange from '../UserPermSelect';
import styles from './style.module.less';

const cx = classBind.bind(styles);


const MemberItem = ({
  memberInfo,
  keyWord,
  onChangePermission,
  isShowOperate,
}) => {
  const { email, avatar, user_cn: name, exist, depart, hrStatus, showOuter, orgName } = memberInfo;

  const changePermission = ({ permNum, role }) => {
    onChangePermission({ member: memberInfo, permission: permNum, role });
  };

  const Tag = ({ content, className = '' }) => {
    return (
      <span className={cx('tag', className)}>
        <span className={cx('word')}>{intl.t(content)}</span>
      </span>
    );
  };

  return (
    <div
      className={cx('addMember-member-item', {
        'quit-member': hrStatus === 'I',
      })}
    >
      <img
        className={cx('pic')}
        src={avatar} />
      <div className={cx('info')}>
        <div className={cx('nameArea')}>
          <div
            className={cx('name')}
          >{highlight(name)}</div>
          <DepartContent department={showOuter ? [orgName] : depart} />
          {hrStatus === 'I' && (
            <Tag
              content={intl.t('已离职')}
              className={cx('tag-quit-self')} />
          )}
          {
            showOuter && <Tag
              content={intl.t('外部')}
              className={cx('tag-self')} />
          }
        </div>
        <p
          className={cx('mail')}
        >{highlight(email)}</p>
      </div>

      <React.Fragment>
        {exist ? (
          <div className={cx('disabled', 'powerChoose')}>{intl.t('已添加')}</div>
        ) : isShowOperate ? (
        // <UserPermChangeNew
        //   memberInfo={memberInfo}
        //   onChooseCallback={changePermission}
        //   configForAddMember={{
        //     hiddenRemoveEntry: true, // 是否开放 恢复/移除入口
        //     hiddenNoPermEntry: true, // 是否开放 无权限成员入口
        //     isHiddenRequest: true, // 是否发出请求
        //     showAdminEntry: true, // 是否开放 管理员入口
        //     canOperateEntry: true,
        //   }}
        //   resourceId={resourceId}
        //   resourceType={resourceType}
        // />
          <UserPermChange
            memberInfo={memberInfo}
            onChangePermission={changePermission}
            />
        ) : (
          ''
        )}
      </React.Fragment>
    </div>
  );
};

export default MemberItem;
