import React, { useMemo, useContext } from 'react';
import { intl } from 'di18n-react';
import { highlight } from '@/utils';
import classBind from 'classnames/bind';
import DepartContent from '../../departContent';
import UserPermSelectNew from '../UserPermChangeNew';
import TeamContext from '@/components/CooperFoldAuth/TeamContext';

import styles from './style.module.less';


const cx = classBind.bind(styles);

const Tag = ({ content, className = '' }) => {
  return (
    <span className={cx('tag', className)}>
      <span className={cx('word')}>{intl.t(content)}</span>
    </span>
  );
};

const TagText = ({ tagList, resourceType }) => {
  if (resourceType === 'doc') {
    if (tagList.indexOf('RESOURCE_OWNER') !== -1 || tagList.indexOf('owner') !== -1) {
      return <Tag
        content={intl.t('{slot}所有者', {
          slot: resourceType === 'dir' ? intl.t('文件夹') : intl.t('文档'),
        })} />;
    }
    if (tagList.indexOf('SPACE_OWNER') !== -1) {
      return <Tag content={intl.t('空间所有者')} />;
    }
    return '';
  }
  if (tagList.indexOf('SPACE_OWNER') !== -1) {
    return <Tag content={intl.t('空间所有者')} />;
  }
  if (tagList.indexOf('RESOURCE_OWNER') !== -1 || tagList.indexOf('owner') !== -1) {
    return <Tag
      content={intl.t('{slot}所有者', {
        slot: resourceType === 'dir' ? intl.t('文件夹') : intl.t('文档'),
      })} />;
  }
  return '';
};

const MemberItem = ({ memberInfo, userRole, refresh, hideChangePerm, spaceRole }) => {
  const {
    tag,
    self,
    email,
    avatar,
    cooperate_with_cn: name,
    id,
    department,
    showOuter,
    orgName,
  } = memberInfo;
  const { hrStatus } = memberInfo;
  const isShort = useMemo(() => {
    return (
      tag.indexOf('SPACE_ADMINISTRATOR') !== -1
      || tag.indexOf('RESOURCE_OWNER') !== -1
    );
  }, [tag]);

  const { resourceId, resourceType } = useContext(TeamContext);

  return (
    <div
      className={cx('cooperFoldAuthm-member-list', {
        'quit-member': hrStatus === 'I',
      })}
      key={id}
    >
      <img
        className={cx('pic')}
        src={avatar} />
      <div className={cx('info')}>
        <div className={cx('nameArea')}>
          <div
            className={cx('name')}
          >{highlight(name)}</div>
          <DepartContent
            department={showOuter ? [orgName] : department}
            isShort={isShort} />
          {/* {self && <Tag
            content={intl.t('我')}
            className={cx('tag-self')} />} */}
          {hrStatus === 'I' && (
            <Tag
              content={intl.t('已离职')}
              className={cx('tag-quit-self')} />
          )}
          {
            showOuter && <Tag
              content={intl.t('外部')}
              className={cx('out-yellow')} />
          }
          <TagText
            tagList={tag}
            resourceType={resourceType} />
        </div>
        <p
          className={cx('mail')}
        >{ highlight(email)}</p>
      </div>
      {!hideChangePerm && (
        <UserPermSelectNew
          memberInfo={memberInfo}
          // onChooseCallback={refresh}
          onRefresh={refresh}
          userRole={userRole}
          resourceId={resourceId}
          resourceType={resourceType}
          spaceRole={spaceRole}
        />
      )}
    </div>
  );
};

export default MemberItem;
