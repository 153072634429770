import React from 'react';
import MemberItem from '../memberItem';
import classBind from 'classnames/bind';
import styles from './style.module.less';


const cx = classBind.bind(styles);

const MemberList = ({ list, refresh, keyWord, userRole, spaceRole }) => {
  return <div
    className={cx('memberList')}
    >
    {list && list.map((item, index) => {
      return <div
        className={cx('memberItem')}
        key={item.email}>
        <MemberItem
          memberInfo={item}
          refresh={refresh}
          keyWord={keyWord}
          userRole={userRole}
          spaceRole={spaceRole}
          />
      </div>;
    }) }

  </div>;
};

export default MemberList;
