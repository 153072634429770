import React, { useState, useEffect, useMemo, forwardRef, useImperativeHandle } from 'react';
import { intl } from 'di18n-react';
import { message, Popover, Tooltip } from 'antd';
import classBind from 'classnames/bind';
import { debounce } from 'lodash-es';
import inheritNoCheck from '../../icon/inheritNoCheck.png';
import inheritCheck from '../../icon/inheritCheck.png';
import { setCooperationType, getAllShare } from '../../service';
import cooperConfirm from '@/components/common/CooperConfirm';
import { getInheritStatus } from '../service';
import styles from './style.module.less';
import { getApolloConfig } from '@/utils/ab';
import { openNewWindow } from '@/utils';

const cx = classBind.bind(styles);

// TODO：兼容文档形式的文案
const InheritContent = forwardRef(({
  updateInheritStatus, resourceId, teamId, callback, spaceRole, resourceType,
}, ref) => {
  const [lock, setLock] = useState(false);
  const [inheritMsg, setInheritMsg] = useState({});
  const [globalOutsideChain, setGlobalOutsideChain] = useState({});


  useEffect(() => {
    getApolloConfig('chain_of_outside').then((res) => {
      setGlobalOutsideChain(res);
    });
  }, []);

  const parentText = useMemo(() => {
    if (JSON.stringify(inheritMsg) === '{}') return null;
    return inheritMsg.isRootResource ? intl.t('团队空间single') : intl.t('父级文件夹')
  }, [inheritMsg]);

  const resourceTypeString = useMemo(() => {
    return resourceType === 'dir' ? intl.t('文件夹') : intl.t('文档')
  }, [resourceType]);

  const INHERIT_SPACE_PERMISSIONS = () => [
    {
      title: intl.t('继承{slot}权限', {
        slot: parentText,
      }),
      tip: intl.t('跟{slot}成员及权限保持一致', {
        slot: parentText,
      }),
      status: true,
    },
    {
      title: intl.t('不继承{slot}权限', {
        slot: parentText,
      }),
      tip: intl.t('{type}成员及权限独立，不随{slot}变化', {
        slot: parentText,
        type: resourceTypeString,
      }),
      status: false,
    },
  ];

  useImperativeHandle(ref, () => ({
    parentText,
  }));

  useEffect(() => {
    getInheritMsg();
  }, [])


  const getInheritMsg = () => {
    getInheritStatus({
      spaceId: teamId,
      resourceId,
    }).then((res) => {
      setInheritMsg({
        isInherit: res.extensionType === 0,
        isRootResource: res.parentNodeType === 'space',
        parentNodeId: res.parentNodeId,
      })
      updateInheritStatus(res.extensionType === 0);
    })
  }

  // 切换继承状态
  const modifyInheritance = (status) => {
    setCooperationType({
      resourceId,
      spaceId: teamId,
      extensionType: status ? 0 : 1,
      parentNodeType: inheritMsg.isRootResource ? 'space' : 'resource',
      parentNodeId: inheritMsg.parentNodeId,
    }).then((res) => {
      setInheritMsg({
        ...inheritMsg,
        isInherit: res.extensionType === 0,
      });
      updateInheritStatus(res.extensionType === 0);
      callback();
      message.success(intl.t('已修改继承方式'));
      (res.extensionType === 0) ? window.__OmegaEvent('ep_teamspace_more_permisionset_inherit_confirm_ck') : window.__OmegaEvent('ep_teamspace_more_permisionset_disconnect_confirm_ck');
    }).catch((error) => {
      if (error.errorCode === '501082') {
        message.error(intl.t('你没有权限'));
      } else {
        message.error(error.errorType);
      }
    });
  };

  const getAllShareFun = (statusCurr) => {
    setLock(true);
    getAllShare({ resourceId })
      .then((res) => {
        if (lock) {
          return;
        }
        const { link, direct } = res; // link:链接分享总量;direct:定向分享总量
        cooperConfirm({
          width: 480,
          zIndex: 2001,
          type: 'warn',
          title: intl.t('确认不继承{slot}权限吗？', {
            slot: parentText,
          }),
          content: <div>
            <p>{intl.t("切换为不继承后，仅{type}管理员和'单独授权'的用户可以访问该{type}。", {
              type: resourceTypeString,
            })}</p>
            {
              (link + direct) !== 0 && <p>{intl.t('当前{type}存在分享记录，获得文件夹/文件分享链接的用户将无法访问。', {
                type: resourceTypeString,
              })}</p>
            }
          </div>,
          onOk: () => modifyInheritance(statusCurr),
          okText: intl.t('确定'),
          cancelText: intl.t('取消'),
        })
        setLock(false);
      });
  };

  const inheritSpace = debounce((data) => {
    const { status } = data;
    if (inheritMsg.isInherit === status) {
      return;
    }
    if (status) {
      cooperConfirm({
        zIndex: 2001,
        type: 'warn',
        title: intl.t('确认继承{slot}权限吗？', {
          slot: parentText,
        }),
        content: intl.t('切换为继承后，{slot}内所有的成员都可以访问{type}', {
          slot: parentText,
          type: resourceTypeString,
        }),
        onOk: () => modifyInheritance(status),
        okText: intl.t('确定'),
        cancelText: intl.t('取消'),
      });
      window.__OmegaEvent('ep_teamspace_more_permisionset_inherit_ck');
    } else {
      getAllShareFun(status);
      window.__OmegaEvent('ep_teamspace_more_permisionset_disconnect_ck');
    }
  }, 500);

  const _renderContent = () => {
    return (
      <div className={cx('inherit-set-wrap')}>
        <div className={cx('inherit-content')}>
          {
          (INHERIT_SPACE_PERMISSIONS() || []).map((item) => {
            return (
              <div
                className={cx('container', {
                  'change-container-inherit': item.status === inheritMsg.isInherit && inheritMsg.isInherit,
                  'change-container-noninherit': item.status === inheritMsg.isInherit && !inheritMsg.isInherit,
                })}
                onClick={() => inheritSpace(item)}
                key={!!item.status}>
                <img
                  className={cx('container-left')}
                  src={ item.status === inheritMsg.isInherit ? inheritCheck : inheritNoCheck}/>
                <div className={cx('container-right')}>
                  <span
                    className={cx('right-title', {
                      'change-right-title': item.status === inheritMsg.isInherit,
                    })}>{item.title}</span>
                  <span className={cx('right-tip')}>{item.tip}</span>
                </div>
              </div>
            );
          })
        }
        </div>
        <div
          className={cx('container-footer')}
          onClick={() => openNewWindow(resourceType === 'dir' ? globalOutsideChain.user_perm_explain : globalOutsideChain.user_perm_explain_doc)}
        >
          {intl.t('了解操作权限')}
          <i className={cx('dk-iconfont', 'dk-icon-youjiantou1', 'arrow-perm')} />
        </div>
      </div>
    );
  };

  // 是管理员有入口，但是非空间成员，禁用
  const disabledBtn = useMemo(() => {
    return (spaceRole === '');
  }, [spaceRole]);

  return <Popover
    trigger={['click']}
    content={disabledBtn ? null : _renderContent() }
    overlayClassName={cx('cooper-inherit-popover')}
    placement='bottomRight'
    zIndex={1024}
  >
    <Tooltip
      placement={'top'}
      title={disabledBtn ? intl.t('你不是文档所属空间成员，无法修改权限') : null}
    >
      <button
        className={cx('inherit-set-btn', {
          disabled: disabledBtn,
        })}>
        <i
          className={cx('dk-iconfont', 'set-icon', {
            'dk-icon-bujicheng-01': !inheritMsg.isInherit,
            'dk-icon-wendangyangshishezhi': inheritMsg.isInherit,
          })} />
        <span className={cx('set-text')}>{intl.t(inheritMsg.isInherit ? '继承{slot}权限' : '不继承{slot}权限', {
          slot: parentText,
        })}</span>
        <i className={cx('dk-iconfont', 'dk-icon-zhankai', 'icon-zhankai1')} />
      </button>

    </Tooltip>

  </Popover>
})


export default InheritContent;
