import React, { useState, useContext, forwardRef, useImperativeHandle, useEffect } from 'react';
import { Input } from 'antd';
import { intl } from 'di18n-react';
import { debounce } from 'lodash-es';
import classBind from 'classnames/bind';
import InfiniteScroll from 'react-infinite-scroller';
import MemberList from '../memberList';
import TeamContext from '@/components/CooperFoldAuth/TeamContext';
import { getCooperatorsForFolder, getCooperators } from '../service';
import AddMemberChoose from '@/components/CooperFoldAuth/AddMemberChoose';
import useLoadMore from '@/hooks/useLoadMore';
import SpinRender from '@/components/SpinRender';
import EmptyInfo from '@/components/CooperFoldAuth/emptyInfo';
import styles from './style.module.less';

const cx = classBind.bind(styles);

const MemberContainer = forwardRef(({
  showAddMemberBtn,
  updateTotalCount,
  userRole,
  spaceRole,
  resourceType,
  parentText,
}, ref) => {
  const { resourceId } = useContext(TeamContext);
  const [keyword, setKeyword] = useState('');


  const {
    loadMore: loadMoreCurrList,
    loading: loadingMoreCurr,
    list: currUserList,
    hasMore: hasMoreCurrList,
    totalCount,
  } = useLoadMore((args) => {
    return resourceType === 'dir'
      ? getCooperatorsForFolder({ q: keyword, resourceId, ...args })
      : getCooperators({ keyword, resourceId, ...args });
  }, true);


  useEffect(() => {
    if (keyword !== '') return;
    updateTotalCount(totalCount)
  }, [totalCount])

  useImperativeHandle(ref, () => ({
    loadMoreCurrList,
  }));

  const onInputChange = debounce((value) => {
    setKeyword(value);
    loadMoreCurrList({ q: value, keyword: value, resourceId }, true);
    window.__OmegaEvent('ep_teamspace_more_permisionset_searchmember_ck', '', {
      platform: 'new',
    });
  }, 500);

  return (
    <div className={cx('resource-member-wrap')}>
      <div className={cx('list-header-input')}>
        <Input
          allowClear
          className={cx('search-input')}
          onChange={(e) => onInputChange(e.target.value)}
          placeholder={intl.t('搜索协作成员')}
        />
        {showAddMemberBtn
          && <div className={cx('add-member-content')}>
            <div className={cx('split-line')} />
            <AddMemberChoose
              resourceId={resourceId}
              parentText={parentText}
              refresh={() => {
                loadMoreCurrList({ resourceId }, true);
              }}
          />
          </div>}
      </div>


      <div className={cx('list-wrap')}>
        {/* <div className={cx('list-wrap-header')}>
          <div className={cx('list-header-title')}>{intl.t('成员列表')}</div>
        </div> */}
        <div
          className={cx('list-wrap-content')}
        >
          <InfiniteScroll
            initialLoad={false}
            pageStart={0}
            loadMore={() => loadMoreCurrList({ keyword })}
            hasMore={!loadingMoreCurr && hasMoreCurrList}
            useWindow={false}
            getScrollParent={() => document.querySelector('.list-wrap-content')}
          >
            <MemberList
              list={currUserList}
              userRole={userRole}
              spaceRole={spaceRole}
              refresh={() => {
                loadMoreCurrList({ resourceId }, true);
              }}
            />
          </InfiniteScroll>

          <SpinRender loading={loadingMoreCurr} />
          {!loadingMoreCurr && currUserList.length === 0 && <EmptyInfo desc={intl.t('暂无搜索结果')} />}
        </div>

      </div>
    </div>
  );
})

export default MemberContainer;
