/* eslint-disable no-unused-vars */
import React, { useState, useContext, useCallback, useEffect, useRef } from 'react';
import { Modal, Button, Input, Spin, Checkbox, Popover, message } from 'antd';
import { intl, getLocale } from 'di18n-react';
import store from '@/model'
import { BatchChangeRoleForPerm, BatchRemoveAuthMembers, BatchChangeRoleForAdmin, getFolderMember, searchFolderMember } from '../batchManage'
import InfiniteScroll from 'react-infinite-scroller';
import MemberList from '../memberList';
import useLoadMore from '../useLoadMore';
import { debounce } from 'lodash-es';
import EmptyInfo from '../emptyInfo';
import TeamContext from '@/components/CooperFoldAuth/TeamContext';
import DropdownCheckbox from '@/components/common/DropdownCheckbox';
import { getPermissionOptions, PERM_DEFAULT_LIST } from '../../contants';
import { teamPermis as permissionOptions } from '@/components/CooperOperation/CreateTeam';
import { ROLE_TYPE } from '@/components/CooperFoldAuth/MemberListOfResource/constants';
import { getPermis } from '@/utils/index';
import './index.less';

export const roleMap = {
  RESOURCE_OWNER: 'ADMIN',
  RESOURCE_ADMIN: 'ADMIN',
  RESOURCE_MEMBER: 'MEMBER',
  RESOURCE_NOPERM_MEMBER: 'NO_PERM',
};

const folderPermsDefault = getPermissionOptions();
const BatchModal = (props) => {
  const { resourceId, userRole } = useContext(TeamContext);
  const { visible, onClose, callback, isInherit } = props;
  const [keyword, setKeyword] = useState('');
  const [selectedList, setSelectedList] = useState([]);
  const [batchPlaceholder, saveBatchPlaceholder] = useState(intl.t('请选择'));
  const [batchValue, saveBatchValue] = useState([0]);
  const [allCheckedValue, setAllCheckedValue] = useState(false);
  const [formatCurrList, setFormatCurrList] = useState([])
  const [formatSearchList, setFormatSearchList] = useState([])
  const [confirmLoading, setConfirmLoading] = useState(false)
  const { cooperLinkConf } = store.getState().GlobalData;
  // eslint-disable-next-line camelcase
  const { batch_up_link_zh, batch_up_link_en } = cooperLinkConf
  const {
    loadMore: loadMoreCurrList,
    loading: loadingMoreCurr,
    list: currList,
    hasMore: hasMoreCurrList,
  } = useLoadMore((args) => getFolderMember({ resourceId, ...args }));

  const {
    loadMore: loadMoreSearchList,
    loading: loadingMoreSearch,
    list: searchList,
    hasMore: hasMoreSearchList,
  } = useLoadMore((args) => searchFolderMember({ resourceId, q: keyword, ...args }, null));

  const PopoverContent = (text, type) => {
    return (
      <div className="popoverContent">
        {intl.t(text)}，
        <span
          className="link"
          onClick={() => {
            // eslint-disable-next-line no-undef, camelcase
            let url = getLocale() === 'zh-CN' ? batch_up_link_zh : batch_up_link_en;
            url && window.open(url);
          }}>{intl.t('查看其他解决方案')}</span>
        {
          type === 1 && <i
            className='dk-iconfont dk-icon-guanbi1'
            onClick={() => {
              message.destroy();
            }}/>
        }
      </div>
    );
  };
  const formatMembers = (members, role) => {
    const memberList = members.map((u) => {
      const p = u.perm;
      const pp = [];

      for (let i = 0; i < PERM_DEFAULT_LIST.length; i++) {
        /* eslint-disable no-bitwise */
        if (p && PERM_DEFAULT_LIST[i]) {
          pp.push(i);
        }
        /* eslint-enable no-bitwise */
      }
      const permsString = getPermis(u.perm, true).join('/');
      const m = {
        ...u,
        permis: pp,
        perm: u.permission,
        permsString,
      };
      // 超出权限外的不能操作
      m.isDisable = !m.canOperate;


      return m;
    });
    return memberList;
  };

  useEffect(() => {
    setFormatCurrList(formatMembers(currList, userRole))
    setFormatSearchList(formatMembers(searchList, userRole))
  }, [searchList, currList])

  // 每当弹窗打开的时候需要去清空数据
  const init = () => {
    setKeyword('')
    setSelectedList([])
    saveBatchValue([0])
    saveBatchPlaceholder(intl.t('查看'))
    setAllCheckedValue(false)
    loadMoreCurrList({ resourceId }, true)
    setConfirmLoading(false)
  }
  useEffect(() => {
    if (props.visible) {
      init();
    }
  }, [props.visible])

  const onInputChange = debounce((value) => {
    setKeyword(value)
    if (value === '') {
      loadMoreCurrList({ resourceId }, true)
    } else {
      loadMoreSearchList({ q: value, resourceId }, true)
    }
  }, 500, {
    leading: true,
    trailing: false,
  });

  const isShowEditBox = useCallback((m, myrole) => {
    // 登陆人是空间普通管理者 且 当前人是管理者 此时是不能操作的
    if (myrole === 'ADMIN' && roleMap[m.role] === 'ADMIN') return true;
    // 自己不能操作自己
    if (m.self) return true;
    return false;
  }, []);

  // 全选
  const onCheckAllChange = (e) => {
    if (e.target.checked) {
      if (formatCurrList.filter((item) => !item.isDisable).length > 50) {
        message.destroy()
        message.warning(PopoverContent('当前最多支持同时选中50个人', 1), 0);
        setAllCheckedValue(false)
      } else {
        setSelectedList(formatCurrList.filter((item) => !item.isDisable))
        setAllCheckedValue(true)
      }
    } else {
      setSelectedList([])
      setAllCheckedValue(false)
    }
  }

  useEffect(() => {
    if (selectedList.length === formatCurrList.filter((item) => !item.isDisable).length) {
      setAllCheckedValue(true)
    }
    if (selectedList.length === 0) {
      setAllCheckedValue(false)
    }
  }, [selectedList.length])

  // 勾选单个
  const singleCheckChange = (e, memberInfo) => {
    if (e) {
      if (selectedList.length >= 50) {
        message.destroy()
        message.warning(PopoverContent('当前最多支持同时选中50个人', 1), 0);
      } else {
        setSelectedList([...selectedList, memberInfo])
      }
    } else {
      setSelectedList(selectedList.filter((item) => item.orgMemberId !== memberInfo.orgMemberId))
    }
  }
  // 移除单个勾选内容
  const removeSingleItem = (memberInfo) => {
    const memberList = selectedList.filter((item) => item.orgMemberId !== memberInfo.orgMemberId)
    setSelectedList(memberList)
  }

  // 改变权限的时候
  const changeBatchPerms = (value) => {
    const v = value[0] === 0 ? value : [0].concat(value);
    saveBatchValue(v);
    saveBatchPlaceholder('');
  };

  const apiCallback = (isError) => {
    setConfirmLoading(false)
    if (!isError) {
      message.success(intl.t('更新权限成功'));
      callback()
      onClose()
    }
  }

  // 修改权限
  const onHandleChangePerms = (value) => {
    setConfirmLoading(true)
    if (value.length > 0) {
      let parmArr = value[0] === 0 ? value : [0].concat(value);
      let permNum = null;
      parmArr.forEach((v) => {
        permNum += folderPermsDefault[v].perm;
      });
      BatchChangeRoleForPerm(resourceId, selectedList, permNum, apiCallback)
    } else if (batchPlaceholder === intl.t('移除成员')) {
      BatchRemoveAuthMembers(resourceId, selectedList, apiCallback)
    } else {
      const roleCode = batchPlaceholder === intl.t('设为管理员') ? 'RESOURCE_ADMIN' : 'RESOURCE_MEMBER';
      BatchChangeRoleForAdmin(resourceId, selectedList, roleCode, apiCallback)
    }
  };

  // 设置操作项有哪些
  const getBatchActions = (allCheckAdminValue) => {
    const res = [];
    const opt = allCheckAdminValue
      ? {
        label: intl.t('取消管理员'),
        handle: () => {
          saveBatchPlaceholder(intl.t('取消管理员'));
          saveBatchValue([]);
        },
      }
      : {
        label: intl.t('设为管理员'),
        handle: () => {
          saveBatchPlaceholder(intl.t('设为管理员'));
          saveBatchValue([]);
        },
      };
    // 超管可以设置管理员
    if (userRole === ROLE_TYPE.SuperAdmin) res.push(opt);

    !isInherit && res.push({
      label: <span className='remove-member'>{intl.t('移除成员')}</span>,
      handle: () => {
        saveBatchPlaceholder(intl.t('移除成员'));
        saveBatchValue([]);
      },
    });
    return res;
  };
  // const allCheckAdmin = !selectedList.find((m) => roleMap[m.role] === 'MEMBER');
  const allCheckAdmin = () => {
    if (selectedList.length === 0) return false
    return !selectedList.find((m) => roleMap[m.role] === 'MEMBER')
  }
  return (
    <>
      <Modal
        visible={visible}
        title={intl.t('批量管理')}
        footer={null}
        key={visible}
        zIndex={2000}
        width={800}
        centered={true}
        onCancel={() => {
          onClose()
          message.destroy()
        }}
        maskClosable={false}
        className="batch-modal"
      >
        <div className="batch-modal-content">
          <div className="batch-modal-content-left">
            <div className="batch-modal-content-left-input">
              <Input
                value={keyword}
                onChange={(e) => {
                  onInputChange(e.target.value);
                }}
                placeholder={intl.t('搜索协作成员')}
                allowClear={true}
              />
            </div>
            {
              !keyword && (
                <div className="list-wrap-title">
                  <div className="list-wrap-title-left">
                    {/* <Checkbox
                      checked={allCheckedValue}
                      className="list-wrap-title-left-checkbox"
                      onChange={(e) => onCheckAllChange(e)}/> */}
                    <Checkbox
                      checked={allCheckedValue}
                      className="list-wrap-title-left-checkbox"
                      onChange={(e) => onCheckAllChange(e)}
                    >
                      <span className="list-wrap-title-left-text">
                        {intl.t('全选')}
                      </span>
                      <span className="list-wrap-title-left-tips">
                        ({intl.t('最多选择50个')})
                      </span>
                    </Checkbox>
                  </div>
                  <div className="list-wrap-title-right">
                    <Popover
                      placement="top"
                      content={PopoverContent('当前仅支持一次性选中50人', 2)}
                      zIndex={9999}
                      overlayClassName="list-wrap-title-popover"
                >
                      <div className="list-wrap-title-right-content">
                        {/* <i className="dk-iconfont dk-icon-shuoming3px" /> */}
                        <span className="list-wrap-title-right-tips">
                          {intl.t('超出选择数量怎么办')}?
                        </span>
                      </div>
                    </Popover>
                  </div>
                </div>
              )
            }
            <div className={`list-wrap ${keyword ? 'search-list' : ''}`}>
              {keyword ? (
                formatSearchList && formatSearchList.length > 0 ? (
                  <InfiniteScroll
                    initialLoad={false}
                    pageStart={0}
                    loadMore={() => loadMoreSearchList()}
                    hasMore={hasMoreSearchList}
                    useWindow={false}
                    getScrollParent={() => document.querySelector('.batch-modal-content-left .list-wrap')}
                  >
                    <MemberList
                      list={formatSearchList}
                      selectedList={selectedList}
                      singleCheckChange={singleCheckChange}
                      keyWord={keyword}
                    />
                  </InfiniteScroll>
                ) : loadingMoreSearch ? (
                  <Spin loading={loadingMoreSearch} />
                ) : (
                  <EmptyInfo desc={intl.t('暂无搜索结果')} />
                )
              ) : formatCurrList && formatCurrList.length > 0 ? (
                <InfiniteScroll
                  initialLoad={false}
                  pageStart={0}
                  loadMore={() => loadMoreCurrList()}
                  hasMore={hasMoreCurrList}
                  useWindow={false}
                  getScrollParent={() => document.querySelector('.batch-modal-content-left .list-wrap')}
                >
                  <MemberList
                    list={formatCurrList}
                    selectedList={selectedList}
                    singleCheckChange={singleCheckChange}
                  />
                </InfiniteScroll>
              ) : loadingMoreCurr ? (
                <Spin loading={loadingMoreCurr} />
              ) : (
                <EmptyInfo desc={intl.t('暂无数据')} />
              )}
            </div>
          </div>
          <div className="batch-modal-content-right">
            <div className="list-wrap-title">
              {`${intl.t('已选')} ${selectedList.length}/50`}
            </div>
            <div className="list-wrap">
              <MemberList
                list={selectedList}
                removeSingleItem={removeSingleItem}
                type="selected" />
            </div>
          </div>
        </div>
        <div className="batch-modal-footer">
          <div className="batch-modal-footer-left">
            {intl.t('批量修改权限为')}：
            <DropdownCheckbox
              options={permissionOptions()}
              placeholder={batchPlaceholder}
              actions={getBatchActions(allCheckAdmin())}
              value={String(batchValue)}
              placement='left'
              // isSpaceMember='true'
              onChange={(value, newOptions) => changeBatchPerms(value, newOptions)}
              />
          </div>
          <div className="batch-modal-footer-right">
            <Button
              className="batch-modal-footer-right-btn"
              onClick={onClose}>
              {intl.t('取消')}
            </Button>
            <Button
              disabled={ batchPlaceholder === intl.t('请选择') || selectedList.length === 0 }
              className="batch-modal-footer-right-btn"
              type="primary"
              loading={confirmLoading}
              onClick={() => {
                onHandleChangePerms(batchValue);
              }}
            >
              {intl.t('确认修改')}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default BatchModal;
