import { Popover } from 'antd';
import classnames from 'classnames/bind';
import styles from './style.module.less';
import { intl, getLocale } from 'di18n-react';
import { isDiDiTenant } from '@/utils/entryEnhance';
import { openNewWindow } from '@/utils';


const permissionConfigTeamUser = {
  header: [intl.t('所有者'), intl.t('所需权限')],
  body: [
    {
      key: {
        text: intl.t('新建/上传'),
        row: 1,
      },
      value: {
        text: intl.t('上传'),
        row: 1,
      },
    },
    {
      key: {
        text: intl.t('下载'),
        row: 1,
      },
      value: {
        text: intl.t('下载'),
        row: 1,
      },
    },
    {
      key: {
        text: intl.t('分享'),
        row: 1,
      },
      value: {
        text: intl.t('分享'),
        row: 1,
      },
    },
    {
      key: {
        text: intl.t('移动到'),
        row: 1,
      },
      value: {
        text: [intl.t('当前位置：编辑/上传/下载'), intl.t('目标位置：编辑/上传')],
        row: 2,
      },

    },
    {
      key: {
        text: intl.t('复制到'),
        row: 1,
      },
      value: null,
    },
    {
      key: {
        text: intl.t('重命名'),
        row: 1,
      },
      value: {
        text: intl.t('编辑'),
        row: 1,
      },
    },
    {
      key: {
        text: intl.t('创建副本'),
        row: 1,
      },
      value: {
        text: intl.t('上传/下载'),
        row: 1,
      },
    },
    {
      key: {
        text: intl.t('删除'),
        row: 1,
      },
      value: {
        text: intl.t('对应文件管理员'),
        row: 1,
      },
    },
  ],
}

const cx = classnames.bind(styles);

const PermissionsPop = ({
  permissionConfig = permissionConfigTeamUser,
  title = intl.t('团队成员协作权限'),
  hiddenText,
  width,
  entryType = 'member',
  bottomLink,
}) => {
  const renderContent = () => {
    return (
      <div
        className={cx({ pop: true, 'pop-us': getLocale() === 'en-US' })}
        style={{ width }}
      >
        <p className={cx('pop-title')}>{title}</p>
        {
          entryType === 'teamSet' && <p className={cx('pop-desc-tip')}>{intl.t('成员初始权限仅影响用户首次主动申请加入团队空间时的权限，不能批量修改团队空间内成员权限')}</p>
        }
        <table
          className={cx('pop-content')}
          border="1">
          {
            permissionConfig.header && <thead>
              <tr>
                {permissionConfig.header.map((headerText, index) => (
                  <th
                    key={index}
                    className={cx('pop-content-item-title', 'pop-content-item-con')}>{headerText}</th>
                ))}
              </tr>
            </thead>
          }
          <tbody>
            {permissionConfig.body.map((permission, index) => (
              <tr key={index} >
                {/* Key Column */}
                <td
                  className={cx('pop-content-item-con', 'pop-content-item-key')}
                >
                  {permission.key.text}
                </td>
                {/* Value Column */}
                {permission.value && (
                  permission.value.row > 1 ? (
                    <td
                      rowSpan={permission.value.row}
                      className={cx('pop-content-item-con', 'pop-content-item-value')}
                    >{Array.isArray(permission.value.text)
                      ? permission.value.text.map((textItem) => {
                        return <p>{textItem}</p>
                      })
                      : permission.value.text}
                    </td>
                  ) : (
                    <td className={cx('pop-content-item-con', 'pop-content-item-value')}>
                      {Array.isArray(permission.value.text)
                        ? permission.value.text.map((textItem) => {
                          return <p>{textItem}</p>
                        })
                        : permission.value.text}
                    </td>
                  )
                )}
              </tr>
            ))}
          </tbody>
          <tr />
        </table>
        {
          bottomLink && isDiDiTenant() && <p
            className={cx('pop-desc')}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              openNewWindow(bottomLink.link)
            }}>
            {bottomLink.text}
            <i
              className={cx({
                'dk-iconfont': true,
                'dk-icon-shouqi': true,
                'more-tip': true,
              })} />
          </p>
        }
      </div >
    )
  }
  return (
    <Popover
      content={renderContent()}
      placement='bottomLeft'
      zIndex={9999}
    >
      <span
        className={cx('permissions-tips-content', {
          hasHover: !hiddenText,
        })}>
        <i
          className={cx({
            'dk-iconfont': true,
            'dk-icon-shuoming3px': true,
            'permission-tip': true,
          })} />
        {
          !hiddenText
          && <span className={cx('team-set')}>
            {entryType === 'teamSet' ? intl.t('权限说明1') : intl.t('了解权限与操作对应关系')}
          </span>
        }
      </span>
    </Popover>
  )
}

export default PermissionsPop;
