
import React from 'react';
import { intl } from 'di18n-react';
import classNames from 'classnames/bind';
import { Tooltip } from 'antd';
import styles from './style.module.less';


const cx = classNames.bind(styles);

const OptionItem = ({ optionItem, onOptionClickCallback }) => {
  const onOptionClick = (optionItemCurr) => {
    onOptionClickCallback(optionItemCurr);
  };

  return (
    <div
      key={optionItem.value}
      onClick={() => !optionItem.disabled && onOptionClick(optionItem)}
      className={cx('perm-option-item', {
        'disabled-perm': optionItem.disabled,
      })} >
      <div>
        <div className={cx('perm-option-label')}>{optionItem.label}</div>
        {
            optionItem.desc
            && <div className={cx('perm-option-desc')}>{optionItem.desc}</div>
          }
      </div>
      {optionItem.checked && <i
        className={cx('select-arrow-icon', {
          'select-arrow-disable__ico': optionItem.disabled,
        })}
        />}
    </div>
  );
};

export default OptionItem;
