import React, { useState, useContext, useMemo, useEffect, useRef, Fragment } from 'react';
import { message } from 'antd';
import { intl } from 'di18n-react';
import classBind from 'classnames/bind';
import TeamContext from '@/components/CooperFoldAuth/TeamContext';
import { getOperationAuth } from '@/service/cooper/home';
import BatchManagement from '../BatchManagement';
import InheritContent from './InheritContent';
import MemberContainer from './MemberContainer';
import { getUserRole } from './service';
import PermissionsPop from '@/components/PermissionsPop';
import { parsePerm } from '@/components/CooperFoldAuth/util';
import { ROLE_TYPE, ROLE_TYPE_DOC, ROLE_TYPE_SPACE } from './constants';
import styles from './style.module.less';


const cx = classBind.bind(styles);


const permConfigDoc = () => {
  return {
    header: null,
    body: [
      {
        key: {
          text: intl.t('查看'),
          row: 1,
        },
        value: {
          text: intl.t('可查看/评论，不能编辑'),
          row: 1,
        },
      },
      {
        key: {
          text: intl.t('编辑'),
          row: 1,
        },
        value: {
          text: intl.t('可查看/评论/编辑，不能分享给他人'),
          row: 1,
        },
      },
      {
        key: {
          text: intl.t('下载'),
          row: 1,
        },
        value: {
          text: intl.t('可查看/下载，不能编辑'),
          row: 1,
        },
      },
      {
        key: {
          text: intl.t('管理员'),
          row: 1,
        },
        value: {
          text: intl.t('可查看/编辑/评论/下载/分享给他人'),
          row: 1,
        },

      },
      {
        key: {
          text: [intl.t('无权限')],
          row: 1,
        },
        value: {
          text: intl.t('不可访问'),
          row: 1,
        },
      },
    ],
  }
}

const permConfigDir = () => {
  return {
    header: null,
    body: [
      {
        key: {
          text: intl.t('查看'),
          row: 1,
        },
        value: {
          text: intl.t('可查看/评论，不能编辑'),
          row: 1,
        },
      },
      {
        key: {
          text: intl.t('编辑'),
          row: 1,
        },
        value: {
          text: intl.t('可查看/评论/编辑，不能分享给他人'),
          row: 1,
        },
      },
      {
        key: {
          text: intl.t('分享'),
          row: 1,
        },
        value: {
          text: intl.t('可分享文件夹和本地上传的文件'),
          row: 1,
        },
      },
      {
        key: {
          text: intl.t('上传'),
          row: 1,
        },
        value: {
          text: intl.t('可在文件夹内上传、新建文件'),
          row: 1,
        },
      },
      {
        key: {
          text: intl.t('下载'),
          row: 1,
        },
        value: {
          text: intl.t('可查看/下载，不能编辑'),
          row: 1,
        },
      },
      {
        key: {
          text: intl.t('管理员'),
          row: 1,
        },
        value: {
          text: intl.t('可查看/编辑/评论/下载/分享给他人'),
          row: 1,
        },

      },
      {
        key: {
          text: [intl.t('无权限')],
          row: 1,
        },
        value: {
          text: intl.t('不可访问'),
          row: 1,
        },
      },
    ],
  }
}

const MemberListOfResource = ({
  refreshFileOrCrumb, // 更新文件列表
  closeFoldAuthModal,
  resourceId,
  teamId,
  folderName,
  goRootFolder,
  resourceType,
  isPersonalTeam,
}) => {
  const [userRole, setUserRole] = useState(null); // 当前登录用户对于当前资源的角色
  const [spaceRole, setSpaceRole] = useState(null);
  const [isInherit, setIsInherit] = useState(null);
  const [totalCount, setTotalCount] = useState(null);
  const [currUserPermText, setCurrentUserPermText] = useState(null);

  const childMemberContainerRef = useRef(null);

  const childInheritContainerRef = useRef(null);

  const ROLE_MAP = useMemo(() => {
    if (resourceType === 'dir') return ROLE_TYPE;
    if (resourceType === 'doc') return ROLE_TYPE_DOC;
    if (resourceType === 'space') return ROLE_TYPE_SPACE;
  }, [resourceType]);


  const RoleTextGlobal = {
    SPACE_OWNER: () => intl.t('管理员'),
    owner: () => intl.t('管理员'),
    RESOURCE_OWNER: () => intl.t('管理员'),
    admin: () => intl.t('管理员'),
    RESOURCE_ADMIN: () => intl.t('管理员'),
    member: parsePerm,
    RESOURCE_MEMBER: parsePerm,
    noperm_member: () => intl.t('无权限成员'),
    RESOURCE_NOPERM_MEMBER: () => intl.t('无权限成员'),
  }


  const getUserRoleForFold = () => { // 获取当前登录用户在空间的角色
    getUserRole({
      spaceId: teamId,
      resourceId,
    }).then((res) => {
      if (res.spaceOwner) {
        setUserRole(ROLE_TYPE.SuperAdmin);
      } else {
        setUserRole(res.resourceRole);
      }
      setSpaceRole(res.spaceRole);
    }).catch(() => {
      message.error(intl.t('获取权限失败'));
      setUserRole('');
    });
  };

  const getUserPerm = () => { // 获取当前登录用户的资源权限
    getOperationAuth(resourceId)
      .then((res) => {
        const { perm, roleKey } = res;
        if (roleKey === ROLE_TYPE.Member
          || roleKey === ROLE_TYPE_DOC.Member
          || roleKey === ROLE_TYPE_SPACE.Member
        ) {
          const permObject = RoleTextGlobal[roleKey](perm);
          const permText = permObject.map((i) => {
            return i.label
          }).join('/')
          setCurrentUserPermText(permText);
        } else {
          setCurrentUserPermText(intl.t('管理员')); // 所有者 和管理员显示“管理员”
        }
      })
      .catch(() => {
        message.error(intl.t('获取权限失败'));
      });
  }


  useEffect(() => {
    getUserRoleForFold();
    getUserPerm();
  }, []);

  // 入口权限
  // 文件夹：
  // 空间所有者、文件夹管理员（必须是空间成员）

  // 文档：
  // 空间所有者、文档所有者（可以不是空间成员）、文档管理员（可以不是空间成员）

  const hasManagePerm = useMemo(() => {
    if (resourceType === 'dir') {
      return (
        userRole === ROLE_MAP.SuperAdmin
        // || userRole === ROLE_MAP.Owner
        || userRole === ROLE_MAP.Admin
      );
    }
    if (resourceType === 'doc') {
      return (
        userRole === ROLE_MAP.SuperAdmin
        || userRole === ROLE_MAP.Owner
        || userRole === ROLE_MAP.Admin
      );
    }
  }, [resourceType, userRole, spaceRole, ROLE_MAP])

  // 文件夹的超管和普管有权限 SuperAdmin:空间所有者
  const showInherit = useMemo(() => {
    if (isPersonalTeam) return false;
    return hasManagePerm;
  }, [hasManagePerm]);

  // 不继承;  只有文件夹显示添加成员入口，文档不显示
  const showAddMemberBtn = useMemo(() => {
    if (resourceType !== 'dir') return false;
    return isInherit === false && hasManagePerm;
  }, [isInherit, hasManagePerm, resourceType]);

  // 同继承
  const showBatchMemberBtn = useMemo(() => {
    return hasManagePerm;
  }, [hasManagePerm]);

  // 更新用户列表
  const updateUserList = () => {
    if (childMemberContainerRef.current) {
      childMemberContainerRef.current.loadMoreCurrList({ resourceId }, true);
    }
  }


  return (
    <TeamContext.Provider value={{ resourceId, teamId, closeFoldAuthModal, goRootFolder, userRole, resourceType }}>
      <div
        className={cx('member-list-of-resource')}
        id='docs-cooperator-list'
      >
        <div className={cx('list-header')}>
          <div className={cx('list-header-title')}>
            {intl.t('协作成员')}
            {
              totalCount > 0 && `· ${totalCount}`
            }
          </div>
          <div className={cx('title-action')}>
            {
              showBatchMemberBtn && <Fragment>
                <BatchManagement
                  resourceId={resourceId}
                  teamId={teamId}
                  callback={() => {
                    updateUserList();
                  }}
                  isInherit={isInherit}
                />
                {showInherit && <div className={cx('split-line')} />}
              </Fragment>
            }
            {showInherit && <InheritContent
              resourceId={resourceId}
              resourceType={resourceType}
              teamId={teamId}
              ref={childInheritContainerRef}
              spaceRole={spaceRole}
              updateInheritStatus={setIsInherit}
              callback={() => {
                updateUserList();
              }}
            />}

          </div>
        </div>

        <div className={cx('list-body-area')}>
          <MemberContainer
            updateTotalCount={setTotalCount}
            showAddMemberBtn={showAddMemberBtn}
            ref={childMemberContainerRef}
            userRole={userRole}
            resourceType={resourceType}
            spaceRole={spaceRole}
            parentText={childInheritContainerRef.current && childInheritContainerRef.current.parentText}
          />

        </div>
        <div className={cx('list-footer')} >
          <div className={cx('user-perm-show')}>
            <span>{intl.t('我的权限：')}{currUserPermText}</span>
            <PermissionsPop
              permissionConfig={resourceType === 'dir' ? permConfigDir() : permConfigDoc()}
              title={intl.t('成员权限说明')}
              width={351}
              hiddenText={true}
            />
          </div>
          {
            // TOTO:增加文件独有的申请权限/转让所有者逻辑
            // showPermOperate &&
          }
        </div>
      </div>
    </TeamContext.Provider>
  );
};

export default MemberListOfResource;
