import CooperApi from '@/utils/request/api/CooperApi';
import { get, post, del, put } from '@/utils/request/cooper';
import { FOLDAUTH_MEMBERS_PAGESIZE } from './contants';
import qs from 'qs';

// TODO: 老代码
export const getAuthMembers = (resourceId) => {
  return get(CooperApi.GET_DIRECTORY_AUTH_MEMBERS, {
    params: {
      resourceId,
    },
  });
};

export const getFolderMember = (params) => {
  const { resourceId, pageNum, pageSize } = params;
  return get(CooperApi.GET_FOLDER_MEMBER.replace(':resourceId', resourceId), {
    params: {
      pageNum,
      pageSize,
    },
  });
};

export const searchFolderMember = (params) => {
  const { resourceId } = params;
  return get(`${CooperApi.SEARCH_FOLDER_MEMBER.replace(':resourceId', resourceId)}?${qs.stringify(params)}`);
};

export const getUserRole = ({ spaceId, resourceId }) => {
  return get(CooperApi.GET_USER_PERM_FOLDER.replace(':resourceId', resourceId).replace(':teamId', spaceId));
};

export const getInheritStatus = ({ spaceId, resourceId }) => {
  return get(CooperApi.GET_FOLDER_INHERIT_STATUS.replace(':resourceId', resourceId), {
    params: {
      spaceId,
    },
  });
};


export const setCooperationType = ({ parentNodeType, parentNodeId, extensionType, resourceId }) => {
  return post(CooperApi.GET_COOPERATION_TYPE.replace(':resourceId', resourceId), {
    parentNodeType,
    parentNodeId,
    extensionType,
  });
};

export const getAllShare = ({ resourceId }) => {
  return get(CooperApi.GET_ALL_SHARE.replace(':resourceId', resourceId));
};

export const getRemoveOperator = (params) => {
  return post(CooperApi.CHANGE_CANCEL_PERM, { ...params });
};


export const changeRoleForPerm = ({ resourceId, perm, orgMemberId, id }) => {
  let param = {
    memberType: 'User',
    resourceType: 'dir',
    perm,
    orgMemberId,
  };
  if (id) {
    param = {
      ...param,
      id,
    };
  }
  return put(CooperApi.CHANGE_USER_PERM.replace(':resourceId', resourceId), param);
};

export const changeRoleForRole = ({ resourceId, orgMemberId, id, role }) => {
  let param = {
    memberType: 'User',
    resourceType: 'dir',
    role,
    orgMemberId,
  };
  if (id) {
    param = {
      ...param,
      id,
    };
  }
  return put(CooperApi.CHANGE_USER_ROLE.replace(':resourceId', resourceId), param);
};

export const changeRoleForRemoveUser = ({ resourceId, orgMemberId, id }) => {
  // let param = {
  //   memberType: 'User',
  //   resourceType: 'dir',
  //   orgMemberId,
  // };
  // if (id) {
  //   param = {
  //     ...param,
  //     id,
  //   };
  // }
  return del(`${CooperApi.CHANGE_USER_REMOVE.replace(':resourceId', resourceId)}?orgMemberId=${orgMemberId}`);
};

export const addAuthMembers = (resourceId, invitees) => {
  return post(CooperApi.FOLDER_PERM_ADD_MEMBER.replace(':resourceId', resourceId), {
    invitees,
  });
};

export const changeAuthMembers = addAuthMembers;

export const delAuthMembers = (resourceId, memberId) => {
  return del(CooperApi.DELETE_DIRECTORY_AUTH_MEMBERS, {
    resourceId,
    authMemberId: [memberId],
  });
};

export const getTeamMembers = ({
  teamId,
  resourceId,
  pageNum,
  key,
}) => {
  return get(`${CooperApi.FOLDER_PERM_ADD_MEMBER_LIST}?resourceId=${resourceId}&pageNum=${pageNum}&pageSize=${FOLDAUTH_MEMBERS_PAGESIZE}&q=${encodeURIComponent(key)}`);
};


