/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-08-09 16:02:39
 * @LastEditTime: 2024-04-17 16:35:54
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/common/OldGlobalHeader/index.js
 *
 */
import classBind from 'classnames/bind';
import { LargeLogo } from '@/assets/icon/index';
import UserInfo from '@/components/LayoutCooper/IconFamily/UserInfo'
import useGlobalInit from '@/hooks/useGlobalInit';
import { isDiDiTenant } from '@/utils/entryEnhance';
import CustomerService from '@/baseComponents/CustomerService';
import styles from './style.module.less';

const cx = classBind.bind(styles);


// 主站使用左右布局，但如下页面仍然保留上下布局:
// 1. /shares
// 2. /docs
// 3. /teams/invite
// 这些页面顶栏都有 logo，以 hasLogo 标记

const CooperHeader = () => {
  useGlobalInit()
  return (
    <div className={cx({ 'cooper-header': true, 'has-logo': true })}>
      <div className={cx('header-content')}>
        <a href='/'><img
          className={cx('logo')}
          src={ LargeLogo }/></a>
        <div className={cx('item-right')}>
          {/* { isDiDiTenant() && <CooperHelper />} */}
          { isDiDiTenant() && <CustomerService hideSpliteLine={true} hideShowNewFeature={true} /> }
          <UserInfo isForbidTenantChang={true} />
          {/* 切换完租户后回不到原来的页面了 */}
        </div>
      </div>
    </div>
  );
}

export default CooperHeader
