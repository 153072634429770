import { intl } from 'di18n-react';

export const ROLE_TYPE_DOC = {
  Owner: 'owner',
  Admin: 'admin',
  Member: 'member',
  NoPerm: 'noperm_member',
  SuperAdmin: 'SPACE_OWNER',
};

export const ROLE_TYPE = {
  Owner: 'RESOURCE_OWNER',
  Admin: 'RESOURCE_ADMIN',
  Member: 'RESOURCE_MEMBER',
  NoPerm: 'RESOURCE_NOPERM_MEMBER',
  SuperAdmin: 'SPACE_OWNER',
}

export const ROLE_TYPE_TEAM = {
  Owner: 'SPACE_OWNER',
  Admin: 'SPACE_ADMINISTRATOR',
  Member: 'SPACE_MEMBER',
}


export const PERMISSION_VALUE = {
  Admin: 55,
  Member: 35,
};

// 文件夹用户默认权限
export const getPermissionOptionsDir = () => {
  return [
    {
      label: intl.t('查看'),
      value: '1',
      desc: intl.t('可查看，不能编辑'),
      id: 0,
      perm: 1,
      disabled: true,
      type: 'check',
    },
    {
      label: intl.t('编辑'),
      value: '2',
      desc: intl.t('可查看/编辑，不能上传/新建/分享给他人'),
      id: 1,
      perm: 2,
      type: 'edit',
    },
    {
      label: intl.t('上传'),
      value: '8',
      desc: intl.t('可上传/新建'),
      id: 2,
      perm: 8,
      type: 'upload',
    },
    {
      label: intl.t('分享'),
      value: '16',
      desc: intl.t('可分享上传文件，不能分享协作文档'),
      id: 3,
      perm: 16,
      type: 'share',
    },
    {
      label: intl.t('下载'),
      value: '32',
      desc: intl.t('可查看/下载，不能编辑'),
      id: 4,
      perm: 32,
      type: 'download',
    },
  ]
}

// 文档用户默认权限
export const permissionOptionsDoc = () => {
  return [
    {
      label: intl.t('查看'),
      id: 1,
      value: '1',
      perm: 1,
      desc: intl.t('可查看/评论，不能编辑'),
      disabled: true,
    },

    {
      label: intl.t('编辑'),
      id: 2,
      value: '2',
      perm: 2,
      desc: intl.t('可查看/评论/编辑，不能分享给他人'),
    },
    // { label: formatMessage({id: 'common.canUpload'}), value: '8', perm: 8, desc: '用户可以邀请协作者一起协作' },
    // { label: formatMessage({id: 'common.canShare'}), value: '16', perm: 16, desc: '拥有此权限的用户允许分享文件' },
    {
      label: intl.t('下载'),
      id: 32,
      value: '32',
      perm: 32,
      desc: intl.t('可查看/下载，不能编辑'),
    },
  ];
};

// 流程图文件用户默认权限
export const permissionFlow = () => {
  return [
    {
      label: intl.t('查看'),
      id: 1,
      value: '1',
      perm: 1,
      desc: intl.t('可查看/评论，不能编辑'),
      disabled: true,
    },
  ];
};

export const defaultOptions = (resourceType) => {
  switch (resourceType) {
    case 'dir': return getPermissionOptionsDir;
    case 'flow': return permissionFlow; // TODO:根据实际值修改
    case 'doc': return permissionOptionsDoc;// TODO:根据实际值修改
    default: return getPermissionOptionsDir;
  }
}

// 通用转换工具
export const permHelperFn = (optionsOrigin) => {
  return {
    getPermArr(perm) {
      const perms = [];
      optionsOrigin.forEach((item) => {
        if ((item.perm & perm) === item.perm) { // eslint-disable-line no-bitwise
          perms.push(item.perm);
        }
      });
      return perms;
    },
    getPermDataArr(perm) {
      const perms = [];
      optionsOrigin.forEach((item) => {
        if ((item.perm & perm) === item.perm) { // eslint-disable-line no-bitwise
          perms.push(item);
        }
      });
      return perms;
    },
    getPermissionNumber(parmArr) {
      return parmArr.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    },
    getPermTextString(parmArr) {
      return optionsOrigin
        .filter((option) => parmArr.includes(option.perm))
        .map((option) => option.label)
        .join('/');
    },
  }
}
