import React, { useState } from 'react';
import { intl } from 'di18n-react';
import classBind from 'classnames/bind';
import AddMemberModal from '@/components/CooperFoldAuth/addMember';
import styles from './style.module.less';

const cx = classBind.bind(styles);

const AddMemberChoose = ({ refresh, resourceId, parentText }) => {
  const [addMemberOpen, setAddMemberOpen] = useState(null);

  const closeAddMember = () => {
    setAddMemberOpen(false);
  };

  const openAddMember = () => {
    setAddMemberOpen(true);
    window.__OmegaEvent('ep_teamspace_more_permisionset_add_ck', '', {
      platform: 'new',
    });
  };


  return (
    <div>
      <button
        className={cx('add-member-btn')}
        onClick={openAddMember}>
        <i className={cx('dk-icon-tianjiachengyuan2', 'dk-iconfont', 'add-icon')} />
        <span className={cx('add-text')}>{intl.t('添加成员')}</span>
      </button>
      {addMemberOpen && <AddMemberModal
        visible={addMemberOpen}
        onClose={closeAddMember}
        parentText={parentText}
        callback={() => {
          refresh({ resourceId }, true);
        }} />}
    </div>
  )
};

export default AddMemberChoose;
