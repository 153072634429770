/*
 * @Author: guanzhong@didiglobal.com // 可DC联系
 * @Date: 2023-07-11 11:26:56
 * @LastEditTime: 2023-07-22 16:28:37
 * @Description: 简单描述
 * @FilePath: /knowledgeforge/src/components/CooperOperation/AuthTopFolder/index.js
 *
 */
import React from 'react';
import CooperFoldAuth from '@/components/CooperFoldAuth';
import mountAnywhere from '@/utils/mountAnywhere';


function authTopFolder(resourceId, teamId, folderName, goRootFolder, isChildFile, refreshFileOrCrumb) {
  const Comp = (
    <CooperFoldAuth
      refreshFileOrCrumb={refreshFileOrCrumb}
      resourceId={resourceId}
      teamId={teamId}
      folderName={folderName}
      isChildFile={isChildFile}
      goRootFolder={goRootFolder}
    />
  );

  mountAnywhere(Comp);
}

export default authTopFolder;
