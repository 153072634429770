import React, { useEffect, useState, useContext } from 'react';
import { Input, message, Button, Modal } from 'antd';
import { intl } from 'di18n-react';
import { debounce } from 'lodash-es';
import classBind from 'classnames/bind';
import MemberList from './memberList';
import { addAuthMembers, getTeamMembers } from '../service';
import { FOLDAUTH_MEMBERS_PAGESIZE } from '../contants';
import EmptyInfo from '../emptyInfo';
import TeamContext from '@/components/CooperFoldAuth/TeamContext';
import styles from './index.module.less';

const cx = classBind.bind(styles);
// 注意：这个文件存在样式污染，改成css moduels后缺少样式，待排查

const AddMemberModal = ({ visible, callback, onClose }) => {
  const { teamId, resourceId } = useContext(TeamContext);
  const [keyWord, setKeyWord] = useState('');
  const [memberCount, setMemberCount] = useState(0);
  const [searchListInfo, setSearchListInfo] = useState({ loading: false, hasMore: false, pageNum: 0, list: [] });
  const [defaultListInfo, setDefaultListInfo] = useState({ loading: false, hasMore: false, pageNum: 0, list: [] });
  const [checkList, setCheckList] = useState([]);
  const [addAuthLoading, setAddAuthLoading] = useState(false);

  useEffect(() => {
    search('');
  }, []);

  // TODO:暂时可添加成员范围为空间成员
  const parentText = intl.t('团队空间')

  const search = (key, clear = false) => {
    if (key) {
      setSearchListInfo({ ...searchListInfo, loading: true });
    } else {
      setDefaultListInfo({ ...defaultListInfo, loading: true });
    }
    let pageNum = key ? searchListInfo.pageNum : defaultListInfo.pageNum;
    if (clear) {
      pageNum = 0;
    }
    getTeamMembers({
      teamId,
      resourceId,
      pageNum,
      key,
    }).then((res) => {
      let items = ((res && res.items) || []);
      const tempList = items.map((item) => {
        return {
          ...item,
          role: 'RESOURCE_MEMBER',
        };
      });
      const more = (pageNum + 1) * FOLDAUTH_MEMBERS_PAGESIZE < res.totalCount;
      const info = { hasMore: more, pageNum: pageNum + 1, loading: false };
      if (key) {
        const temp = clear ? tempList : searchListInfo.list.concat(tempList);
        setSearchListInfo({ ...info, list: temp });
      } else {
        setMemberCount(res.totalCount);
        const temp = clear ? tempList : defaultListInfo.list.concat(tempList);
        setDefaultListInfo({ ...info, list: temp });
      }
    });
  };

  const onInputChange = debounce((value) => {
    search(value, true);
    setKeyWord(value);
    window.__OmegaEvent('ep_teamspace_more_permisionset_searchadd_ck', '', {
      platform: 'new',
    });
  }, 500);

  const addAuthMember = () => {
    setAddAuthLoading(true);
    const memberList = checkList.map((item) => {
      const temp = {
        ...item,
        inviteeType: 0,
        role: item.role,
      };
      return temp;
    });
    addAuthMembers(resourceId, memberList).then(() => {
      message.success(intl.t('添加成功'));
      callback();
      onClose();
    }).catch(() => {
      message.error(intl.t('添加失败，请重试...'));
    }).finally(() => {
      setAddAuthLoading(false);
    });
    window.__OmegaEvent('ep_teamspace_more_permisionset_successadd_ck', '', {
      platform: 'new',
    });
  };
  const changeCheckList = (val) => {
    setCheckList(val);
  };

  return <Modal
    closable={true}
    wrapClassName={cx('add-auth-members')}
    visible={visible}
    footer={null}
    width={640}
    style={{ top: 100 }}
    maskClosable={false}
    onCancel={onClose}
    title={<div>{intl.t('添加可访问成员')}</div>}
  >
    <div className={cx('addMember')}>
      <Input
        className={cx('search-input')}
        onChange={(e) => onInputChange(e.target.value)}
        placeholder={intl.t('搜索添加当前{slot}成员', {
          slot: parentText,
        })}
      />
      <div className={cx('memberList-title')}>{intl.t('{slot}成员{memberCount}人', { memberCount, slot: parentText })}：</div>
      { keyWord === '' && <MemberList
        checkList={checkList}
        changeCheckList={changeCheckList}
        listInfo={defaultListInfo}
        search={search} /> }
      { keyWord !== ''
      && <div className={cx('searchResult')}>
        { searchListInfo.list.length === 0 && !searchListInfo.loading
          ? <EmptyInfo />
          : <MemberList
              keyWord={keyWord}
              checkList={checkList}
              changeCheckList={changeCheckList}
              listInfo={searchListInfo}
              search={search} /> }
      </div>
    }

      <div className={cx('footer')}>
        <p className={cx('num')}>
          {intl.t('已选择: {num}名成员', { num: checkList.length })}
        </p>
        <div className={cx('btnArea')}>
          <Button
            className={cx('cancel', 'btn')}
            onClick={onClose}>
            {intl.t('取消')}
          </Button>
          <Button
            loading={addAuthLoading}
            disabled={checkList.length === 0}
            className={cx('confirm', 'btn')}
            onClick={addAuthMember}>
            {intl.t('确定')}
          </Button>
        </div>
      </div>
    </div>
  </Modal>
};
export default AddMemberModal;
