import CooperApi from '@/utils/request/api/CooperApi';
import { get, post, del, put } from '@/utils/request/cooper';
import { FOLDAUTH_MEMBERS_PAGESIZE } from '../contants';
import qs from 'qs';


// export const getCooperatorsForFolder = (params) => {
//   const { resourceId, pageNum, pageSize, searchKey } = params;
//   return get(CooperApi.GET_FOLDER_MEMBER.replace(':resourceId', resourceId), params);
// };


export function getCooperators(params) {
  return get(CooperApi.COOPERATORS_PAGE, params).then(({
    items = [],
    totalCount = 0,
    currentPage,
    pageSize,
  }) => {
    items = items.map((p) => ({
      ...p,
      id: p.id,
      username: p.cooperate_with,
      cooperate_with_cn: p.cooperate_with_cn,
      avatar: p.avatar,
      permission: p.permission,
      cooperatorType: p.cooperator_type,
      tag: p.tag,
      role: p.role,
      orgMemberId: p.orgMemberId,
      // A: 在职，I: 离职，其他：未知
      hrStatus: p.hrStatus,
      showOuter: p.showOuter,
      department: p.department, // 暂时后端没有返回
      email: `${p.cooperate_with}@didiglobal.com`,
    }));
    return {
      items,
      totalCount,
      currentPage,
      pageSize,
    };
  });
}


export const getCooperatorsForFolder = (params) => {
  const { resourceId } = params;
  return get(`${CooperApi.SEARCH_FOLDER_MEMBER.replace(':resourceId', resourceId)}?${qs.stringify(params)}`);
};

export const getUserRole = ({ spaceId, resourceId }) => {
  return get(CooperApi.GET_USER_PERM_FOLDER.replace(':resourceId', resourceId).replace(':teamId', spaceId));
};

export const getInheritStatus = ({ spaceId, resourceId }) => {
  return get(CooperApi.GET_FOLDER_INHERIT_STATUS.replace(':resourceId', resourceId), {
    params: {
      spaceId,
    },
  });
};


export const setCooperationType = ({ spaceId, resourceId, extensionType }) => {
  return post(CooperApi.GET_COOPERATION_TYPE.replace(':resourceId', resourceId).replace(':teamId', spaceId), {
    spaceId,
    extensionType,
  });
};

export const getAllShare = ({ resourceId }) => {
  return get(CooperApi.GET_ALL_SHARE.replace(':resourceId', resourceId));
};

export const getRemoveOperator = (params) => {
  return post(CooperApi.CHANGE_CANCEL_PERM, { ...params });
};


export const changeRoleForPerm = ({ resourceId, perm, orgMemberId, id }) => {
  let param = {
    memberType: 'User',
    resourceType: 'dir',
    perm,
    orgMemberId,
  };
  if (id) {
    param = {
      ...param,
      id,
    };
  }
  return put(CooperApi.CHANGE_USER_PERM.replace(':resourceId', resourceId), param);
};

export const changeRoleForRole = ({ resourceId, orgMemberId, id, role }) => {
  let param = {
    memberType: 'User',
    resourceType: 'dir',
    role,
    orgMemberId,
  };
  if (id) {
    param = {
      ...param,
      id,
    };
  }
  return put(CooperApi.CHANGE_USER_ROLE.replace(':resourceId', resourceId), param);
};

export const changeRoleForRemoveUser = ({ resourceId, orgMemberId, id }) => {
  // let param = {
  //   memberType: 'User',
  //   resourceType: 'dir',
  //   orgMemberId,
  // };
  // if (id) {
  //   param = {
  //     ...param,
  //     id,
  //   };
  // }
  return del(`${CooperApi.CHANGE_USER_REMOVE.replace(':resourceId', resourceId)}?orgMemberId=${orgMemberId}`);
};

export const addAuthMembers = (resourceId, invitees) => {
  return post(CooperApi.FOLDER_PERM_ADD_MEMBER.replace(':resourceId', resourceId), {
    invitees,
  });
};

export const changeAuthMembers = addAuthMembers;

export const delAuthMembers = (resourceId, memberId) => {
  return del(CooperApi.DELETE_DIRECTORY_AUTH_MEMBERS, {
    resourceId,
    authMemberId: [memberId],
  });
};

export const getTeamMembers = ({
  teamId,
  resourceId,
  pageNum,
  key,
}) => {
  return get(`${CooperApi.FOLDER_PERM_ADD_MEMBER_LIST}?resourceId=${resourceId}&pageNum=${pageNum}&pageSize=${FOLDAUTH_MEMBERS_PAGESIZE}&q=${encodeURIComponent(key)}`);
};

