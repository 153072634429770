import { intl } from 'di18n-react';
import { useContext, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import InfiniteScroll from 'react-infinite-scroller';
import {
  changePageRole,
  delPageMembers,
  delPageGroup,
} from '@/service/knowledge/page';
import NotificationStatus from '@/constants/notification';
import { useSelector } from 'react-redux';
import useNotification from '@/hooks/useNotification';
import LayoutContext from '@/components/serviceComponents/Layout/layoutContext';
import { highLightKeyword, beautySub } from '@/utils';
import TextOnlyModal from '@/components/TextOnlyModal';
import InheritSelect from '@/components/serviceComponents/PageMemberList/InheritSelect';
import { MemberRoleMap, PAGE_SOURCE, TagRoleMap } from '../config.js';
import MemberEmail from '@/components/serviceComponents/MemberComponent/MemberEmail';
import styles from './style.module.less';

const cls = classNames.bind(styles);

const ExistMemberList = ({
  members,
  loadMore,
  loading,
  hasMore,
  getScrollParent,
  refreshRelatedList,
  keyword,
  isRootNode,
  knowledgeMember,
  getAlonePerm,
}) => {
  const notification = useNotification();
  const { pageId } = useContext(LayoutContext);
  const { globalOutsideChain } = useSelector((state) => state.CooperIndex);
  const [delModalVisible, setDelModalVisible] = useState(false);
  const [delParams, setDelParams] = useState({ id: '', memberType: '' });

  const openDelModal = ({ id, memberType }) => {
    setDelParams({ id, memberType });
    setDelModalVisible(true);
  };

  const delMember = async () => {
    try {
      if (delParams.memberType === 'User') {
        await delPageMembers(pageId, delParams);
      } else {
        await delPageGroup(pageId, { groupId: delParams.id });
      }
      notification(NotificationStatus.SUCCESS, intl.t('移除成功'));
      loadMore({ refresh: true });
      refreshRelatedList && refreshRelatedList({ refresh: true });
      setDelModalVisible(false);
    } catch (err) {
      notification(NotificationStatus.ERROR, err.errorMessage);
    }
  };

  const closeDelModal = () => {
    setDelModalVisible(false);
  };

  const getMemberName = () => {
    const member = members.find((item) => item.id === delParams.id);
    const name = member ? member.cooperate_with_cn : '';
    return name;
  };

  const delModalTitle = () => {
    const user = <span style={{ color: '#ff563b' }}>{getMemberName()}</span>
    if (delParams.memberType === 'User') {
      return <span>{intl.t('是否移除成员')}{':'}{user}{'?'}</span>;
    }
    return <span>{intl.t('是否移除成员组')}{':'}{user}</span>;
  };

  const getMemberCount = (memberCount) => {
    // if (typeof memberCount === 'number') {
    //   return `(${memberCount}人)`;
    // }
    return '';
  };

  const changeInherit = async ({ value, itemMessage }) => {
    const param = { id: itemMessage.id, memberType: itemMessage.memberType };

    if (value === 'PAGE_REMOVE') {
      window.__OmegaEvent('ep_dkpc_pagedetail_member_remove_ck');
      openDelModal(param);
      return;
    }
    if (value === 'RESUME') {
      window.__OmegaEvent('ep_dkpc_pagedetail_recoverinherit_ck');
      try {
        await delPageMembers(pageId, param);
        notification(
          NotificationStatus.SUCCESS,
          intl.t('已恢复该用户权限继承'),
        );
      } catch (error) {
        notification(NotificationStatus.ERROR, error.errorMessage);
      }
    } else {
      window.__OmegaEvent('ep_dkpc_pagedetail_changerole_ck');
      try {
        await changePageRole(pageId, {
          ...param,
          role: value,
          orgMemberId: itemMessage.orgMemberId,
        });
      } catch (error) {
        notification(NotificationStatus.ERROR, error.errorMessage);
      }
    }
    loadMore({ refresh: true });
    refreshRelatedList && refreshRelatedList({ refresh: true });
  };

  const getMemberRoleList = (operationList) => {
    const res = {};
    if (operationList && operationList?.length > 0) {
      operationList.forEach((operation) => {
        res[operation] = MemberRoleMap[operation];
      });
    }
    return res;
  };

  const getChangePermExplain = (sources) => {
    if (sources !== 'ALONE_PERM') {
      return intl.t(
        '如修改权限，该用户将不再继承{slot0}权限，支持恢复继承关系',
        { slot0: isRootNode ? intl.t('如修改权限-知识库') : intl.t('如修改权限-父级页面') },
      );
    }
    return null;
  };

  return (
    <>
      <InfiniteScroll
        initialLoad={false}
        pageStart={0}
        loadMore={() => {
          loadMore({ refresh: false });
        }}
        hasMore={!loading && hasMore}
        useWindow={false}
        getScrollParent={getScrollParent}
      >
        <div className={styles['member-container']}>
          <div className={styles['member-wrapper']}>
            {members?.length > 0
              && members.map((item, index) => (
                <div
                  key={item.principalMemberId || item.id || index}
                  className={styles['member-item']}>
                  <div className={cls('metas')}>
                    <img
                      className={styles['member-avatar']}
                      src={item.avatar}
                      alt=""
                    />

                    <div>
                      <div className={cls('member-title-wrap')}>
                        {
                          <span
                            className={cls(
                              { oneLine: !item.email && !item.groupUsers },
                              'member-username',
                            )}

                          >
                            {
                              highLightKeyword(
                                keyword,
                                beautySub(item.cooperate_with_cn, 12),
                              )
                            }
                          </span>
                        }

                        {getMemberCount(item.memberCount)}
                        {item.tag
                          && item.tag.map((tagRole) => {
                            return (
                              <span
                                className={cls('role-tag')}
                                key={tagRole}>
                                {TagRoleMap[tagRole]}
                              </span>
                            );
                          })}
                         {/* A: 在职，I: 离职，其他：未知 */}
                    {item.hrStatus === 'I' && (
                      <span className={cls('name-label-dimissed')}>{intl.t('已离职')}</span>
                    )}

                      </div>
                      {(item.groupUsers || item.email) && (
                        <MemberEmail
                          groupUsers={item.groupUsers}
                          keyword={keyword}
                          memberEmail={item.email}
                          maxlength={24}
                          customClass={styles['member-email']}
                        />
                      )}
                    </div>
                  </div>
                  <div className={cls('identity-select-wrap')}>
                    <InheritSelect
                      key={Math.random()}
                      inheritValue={item.role}
                      InheritConfig={getMemberRoleList(item.operationList)}
                      inheritValueSource={PAGE_SOURCE[item.sources]}
                      changeInherit={changeInherit}
                      itemMessage={item}
                      bottomLink={{
                        text: intl.t('了解成员角色及其权限'),
                        link: globalOutsideChain?.page_member_perm_explain,
                      }}
                      changePermExplainText={getChangePermExplain(item.sources)}
                      isRootNode={isRootNode}
                      knowledgeMember={knowledgeMember}
                      getAlonePerm={getAlonePerm}
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </InfiniteScroll>
      <TextOnlyModal
        handleOk={delMember}
        handleCancel={closeDelModal}
        modalVisible={delModalVisible}
        title={delModalTitle()}
      />
    </>
  );
};

export default ExistMemberList;
