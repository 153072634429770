import { intl } from 'di18n-react';
import { connect, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import { blurTime } from '@/utils';
import styles from './style.module.less';
import { Modal, Spin } from 'antd';
import { getDidocDetail } from '@/service/knowledge/didoc';
import NotificationStatus from '@/constants/notification';
import useNotification from '@/hooks/useNotification';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LayoutContext from '@/components/serviceComponents/Layout/layoutContext';
import usePermission from '@/hooks/usePermission';
import { DocType } from '@/constants/page';
import { getIsWikiHTML } from '@/constants/wikihtmlrenderHelper';
import { HistoryVersionEmptypage } from '@/assets/icon';

const cls = classNames.bind(styles);

function VersionList(props) {
  const {
    versionList,
    currentVersionIndex,
    changeCurrentVersionIndex,
    isPreview,
    isEdit,
    view,
    docInfo,
    revertVersion,
    toggleHistoryVersionVisible,
    getPageDetail,
    loading,
    setPageDetail,
    changeLoading,
    changeInitLoading,
  } = props;

  const { previewFileId } = useSelector((state) => state.pageDetail);
  const navigate = useNavigate();
  const { knowledgeId, pageId } = useContext(LayoutContext);
  const notification = useNotification();
  const [hasRevertPermission, setHasRevertPermission] = useState(false);
  const { checkOperationPermission } = usePermission();
  const fileId = previewFileId.inEdit;
  const currentVersion = versionList[currentVersionIndex];
  const { guid, permission } = docInfo;
  const { teamId } = useParams();
  const confirmRevert = async (e) => {
    e.stopPropagation();
    let hasReleaseContent;
    if (isPreview) {
      hasReleaseContent = isEdit ? fileId === versionList[0].version : false;
    } else {
      // Didoc调用当前接口（wiki html不调用）
      const isWikiHTML = getIsWikiHTML(currentVersion);
      if (!isWikiHTML) {
        const { version } = await getDidocDetail(currentVersion?.guid || guid);
        hasReleaseContent = versionList.some(
          (item) => Number(item.version) === Number(version),
        );
      }
    }

    const content = hasReleaseContent ? (
      <div className={cls('tip-content')}>
        {intl.t('恢复至')}

        <span className={cls('version')}>
          {' '}
          {currentVersion.historyName}
          {intl.t('版本')}
        </span>
        {intl.t('将会覆盖当前内容。')}
      </div>
    ) : (
      <div className={cls('tip-content')}>
        {intl.t('此⻚面有未发布的内容，恢复至')}

        <span className={cls('version')}>
          {' '}
          {currentVersion.historyName}
          {intl.t('版本')}
        </span>
        {intl.t('将会丢失未发布内容。')}

        <span
          className={cls('link')}
          onClick={goToEditor}>
          {intl.t('查看未发布的内容')}
        </span>
      </div>
    );

    Modal.confirm({
      width: 480,
      icon: null,
      centered: true,
      title: intl.t('确认恢复至此版本吗？'),
      content,
      okText: intl.t('确认恢复'),
      cancelText: intl.t('取消'),
      className: cls('revert-version-modal'),
      onOk: handleRevert,
    });
  };
  const goToEditor = () => {
    Modal.destroyAll();
    toggleHistoryVersionVisible();
    navigate(`/knowledge/${knowledgeId}/${pageId}/edit`);
  };
  const handleRevert = () => {
    const { title, version, thumbnail, summary } = currentVersion;
    const isWikiHTML = getIsWikiHTML(currentVersion);
    const revertParam = {
      pageId,
      title,
      version,
      thumbnail,
      summary,
      // pageType: currentVersion.externalSystemType,
      pageType: isPreview ? DocType.DK_FILE : DocType.DK_PAGE,
      guid: currentVersion.guid,
      externalSystemType: currentVersion.externalSystemType,
    };
    if (isWikiHTML) revertParam.externalSystemType = 'html';
    const needDidocRevert = !(isPreview || isWikiHTML);
    const revertVersionPromise = revertVersion(revertParam);
    const revertHistoryPromise = !needDidocRevert
      ? () => {}
      : view.historyRevert({ version, docId: currentVersion?.guid || guid });
    const promiseList = !needDidocRevert
      ? [revertVersionPromise]
      : [revertVersionPromise, revertHistoryPromise];
    Promise.all(promiseList).then(() => {
      setPageDetail({});
      changeLoading(true);
      notification(NotificationStatus.SUCCESS, intl.t('已恢复内容'));
      toggleHistoryVersionVisible();
      getPageDetail({ pageId });
      backToPage();
    });
  };

  const backToPage = () => {
    navigate(
      teamId
        ? `/team-file/${teamId}/knowledge/${knowledgeId}/${pageId}`
        : `/knowledge/${knowledgeId}/${pageId}`,
    );
  };
  useEffect(() => {
    if (permission) {
      setHasRevertPermission(
        checkOperationPermission('MANAGE_PAGE_CONTEXT', permission),
      );
    }
  }, [permission]);
  return (
    <div className={cls('version-list')}>
      <div className={cls('layout')}>
        <div className={cls('header')}>
          <div className={cls('title')}>{intl.t('发布版本')}</div>
        </div>

        <div className={cls('body')}>
          <div className={cls('content')}>
            {loading && (
              <div className={'page-detail-loading'}>
                <Spin />
              </div>
            )}

            <div>
              {versionList && versionList.length > 0 ? (
                versionList.map((item, index) => (
                  <div
                    key={item.id}
                    className={cls('card', {
                      'is-active': currentVersionIndex === index,
                    })}
                    onClick={() => {
                      if (index === currentVersionIndex) return;
                      changeCurrentVersionIndex(index);
                      changeInitLoading(true);
                    }}
                  >
                    <div className={cls('container')}>
                      <div
                        className={cls(
                          'title',
                          currentVersionIndex === index ? 'titleJustify' : '',
                        )}
                      >
                        <p>
                          {item.historyName}
                          {intl.t('版本')}
                          {!index && (
                            <span className={cls('badge')}>
                              {intl.t('最新')}
                            </span>
                          )}
                        </p>
                        {hasRevertPermission && (
                          <span
                            className={cls(
                              'restoretoVersion',
                              currentVersionIndex === index
                                ? 'restoreBlock'
                                : 'restoreNone',
                            )}
                            onClick={confirmRevert}
                          >
                            {intl.t('恢复至此版本')}
                          </span>
                        )}
                      </div>
                      <div className={cls('datetime')}>
                        {blurTime(item.createdOn)}
                      </div>
                      <div className={cls('username')}>
                        {item.createdBy.chineseName}{' '}
                        {item.createdBy.englishName}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className={cls('emptyPage')}>
                  <img
                    className={cls('emptyImg')}
                    src={HistoryVersionEmptypage}
                  />
                  <span className={cls('emptyInfo')}>
                    {intl.t('暂无发布版本')}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps({ historyVersion, pageDetail }) {
  const { docInfo } = pageDetail;
  const { view, versionList, currentVersionIndex, initLoading } = historyVersion;
  return {
    view,
    docInfo,
    versionList,
    initLoading,
    currentVersionIndex,
  };
}

function mapDispatchToProps({ historyVersion, pageDetail }) {
  const {
    revertVersion,
    toggleHistoryVersionVisible,
    changeCurrentVersionIndex,
    changeInitLoading,
  } = historyVersion;
  const { getPageDetail, resetState, setPageDetail } = pageDetail;
  return {
    changeCurrentVersionIndex,
    revertVersion,
    toggleHistoryVersionVisible,
    getPageDetail,
    changeInitLoading,
    changeLoading: pageDetail.changeInitLoading,
    resetState,
    setPageDetail,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VersionList);
