import { useEffect, useState } from 'react';
import { intl } from 'di18n-react';
import BatchModal from './BatchModal/index'
import './index.less'


const BatchManagement = (props) => {
  const { resourceId, teamId, callback, isInherit } = props
  const [showModel, setShowModel] = useState(false)
  const openAddMember = (open) => {
    setShowModel(open)
  }
  return (
    <>
      <button
        className='batch-member-btn'
        onClick={() => openAddMember(true)}>
        <i className='dk-iconfont dk-icon-wendangyangshishezhi' />
        <span className='batch-text'>{intl.t('批量管理')}</span>
      </button>
      {/* 弹窗出现再拉去取用户信息等接口 */}
      {showModel && <BatchModal
        visible={showModel}
        resourceId={resourceId}
        teamId={teamId}
        callback={callback}
        isInherit={isInherit}
        onClose={() => openAddMember(false)} />}
    </>
  )
};

export default BatchManagement
