import CooperApi from '@/utils/request/api/CooperApi';
import { post, del, put } from '@/utils/request/cooper';

export async function getCanOPerateEntryFn(params) {
  let res = await post(CooperApi.GET_COOPERATION_ENTRY_PERM, params);

  return res
}

export const changeRoleForPerm = ({ resourceId, perm, orgMemberId, id, memberType, resourceType }) => {
  let param = {
    memberType,
    resourceType,
    perm,
    orgMemberId,
  };
  if (id) {
    param = {
      ...param,
      id,
    };
  }
  return put(CooperApi.CHANGE_USER_PERM.replace(':resourceId', resourceId), param);
};

export const changeRoleForRole = ({ resourceId, id, orgMemberId, memberType, role, resourceType }) => {
  let param = {
    memberType,
    resourceType,
    role,
    orgMemberId,
  };
  if (id) {
    param = {
      ...param,
      id,
    };
  }
  return put(CooperApi.CHANGE_USER_ROLE.replace(':resourceId', resourceId), param);
};

export const updateCooperReq = (resourceId, orgMemberId, permission, role) => new Promise((resolve, reject) => {
  const url = CooperApi.LDAP_PERMISSION.replace(':id', resourceId);
  put(url, { permission, role, orgMemberId })
    .then(() => resolve())
    .catch((err) => {
      if (err.response && err.response.data) {
        reject(new Error(err.response.data.message));
      } else {
        reject(err);
      }
    });
});


export const changeRoleForRemoveUser = ({ resourceId, orgMemberId, id }) => {
  // let param = {
  //   memberType: 'User',
  //   resourceType: 'dir',
  //   orgMemberId,
  // };
  // if (id) {
  //   param = {
  //     ...param,
  //     id,
  //   };
  // }
  return del(`${CooperApi.CHANGE_USER_REMOVE.replace(':resourceId', resourceId)}?orgMemberId=${orgMemberId}`);
};


export const removeCooperReq = (id, orgMemberId, role) => {
  return del(`${CooperApi.RESOURCE_LDAP.replace(':id', id)}?role=${role}&orgMemberId=${orgMemberId}`);
};

