import React, { useState, useEffect, useMemo, useContext } from 'react';
import { intl } from 'di18n-react';
import { Tooltip, Popover, message } from 'antd';
import { ROLE_TYPE, ROLE_TYPE_DOC, defaultOptions, permHelperFn } from './constants';
import OptionItem from './OptionItem';
import arrowImg from './icon/ico-arrow.png';
import {
  getCanOPerateEntryFn,
  changeRoleForPerm,
  changeRoleForRole,
  changeRoleForRemoveUser,
  removeCooperReq,
  updateCooperReq,
} from './service';
import './style.less';


const READ_VALUE = 1;

const UserPermChangeNew = ({
  memberInfo = {}, // 当前被操作的用户
  userRole = '', // 当前登录用户的资源角色
  spaceRole, // 当前登录用户在空间的角色
  onChooseCallback = () => { }, // 操作完权限、移除恢复之后执行的内容
  onRefresh = () => {}, // 更新用户列表函数
  resourceId,
  resourceType,
  configForAddMember = {},
}) => {
  const { role, perm, permission, sources = 'NO_SHOW', canOperate, id, orgMemberId } = memberInfo || {}; // TODO:这个id指的是什么

  const defaultPerm = resourceType === 'dir' ? perm : permission;

  const SOURCE_MAP = () => {
    return {
      ALONE_PERM: resourceType === 'dir' ? intl.t('当前文件夹单独授权') : intl.t('当前文档单独授权'),
      EXTEND_PARENT: intl.t('继承自父文件夹'),
      NO_SHOW: '',
    }
  };

  const {
    hiddenRemoveEntry,
    hiddenNoPermEntry,
    isHiddenRequest,
    showAdminEntry,
    canOperateEntry,
  } = configForAddMember;


  const showOperateArrow = useMemo(() => {
    if (JSON.stringify(configForAddMember) !== '{}') return canOperateEntry;
    return canOperate;
  }, [configForAddMember, canOperate, resourceType])


  // 两者结合形成最终的权限。
  const [permValueArr, setPermValueArr] = useState([]); // 权限组成的数组，初始是perm，跟随用户选择变化
  const [roleString, setRoleString] = useState(role); // 当前用户的角色值


  const [sourcesData, setSourcesData] = useState(sources); // 前端控制“单独授权”标记、是否可以恢复，操作后从后端重新拉列表对用户不友好


  const [visible, setVisible] = useState(false);
  const [canRemove, setCanRemove] = useState();
  const [affixEntry, setAffixEntry] = useState({
    showAdmin: null,
    showNoPerm: null,
  });


  const DataSetPermsDefault = useMemo(() => {
    return defaultOptions(resourceType)();
  }, [resourceType]); // 默认权限数据集


  const permHelperOBj = useMemo(() => {
    return permHelperFn(DataSetPermsDefault)
  }, [resourceType]) // 数据集下的权限操作函数，用来做权限各种格式的转换


  useEffect(() => {
    setPermValueArr(permHelperOBj.getPermArr(defaultPerm));
  }, [defaultPerm]);


  const _formatePermText = () => {
    if ([ROLE_TYPE.Admin, ROLE_TYPE_DOC.Admin, ROLE_TYPE.Owner,
      ROLE_TYPE_DOC.Owner, ROLE_TYPE.SuperAdmin, ROLE_TYPE_DOC.SuperAdmin].includes(roleString)) {
      return intl.t('管理员');
    }
    if ([ROLE_TYPE.NoPerm, ROLE_TYPE_DOC.NoPerm].includes(roleString)) {
      return intl.t('无权限成员');
    }
    // 权限组成的数组转成权限字符串
    return permHelperOBj.getPermTextString(permValueArr);
  }

  const getCanOPerateEntry = async () => {
    if (showAdminEntry !== null && hiddenNoPermEntry) return;

    // 权限下拉框是否显示管理员/无权限成员
    const affixEntryRes = await getCanOPerateEntryFn({
      principalMemberId: memberInfo.principalMemberId,
      principalType: memberInfo.principalType,
      resourceId,
    });


    // 操作者是空间所有者、文件夹所有者、文档所有者时有设置管理员权限
    // 被操作者是空间成员才可以被设置为管理员
    let showAdmin;
    if (resourceType === 'doc') {
      // 空间所有者，可以修改任何人的权限；
      // 文档所有者，可以操作除空间所有者之外的任何人为管理员；
      // 文件夹所有者，不能设置任何人为管理员；
      // 空间外不能被修改为管理员；

      showAdmin = affixEntryRes.permissionValue > 0 // 操作的用户是空间成员
        && (spaceRole === ROLE_TYPE_DOC.SuperAdmin || userRole === ROLE_TYPE_DOC.Owner);
    } else { // TODO：分内外之后，增加判断是否是空间内成员的逻辑
      showAdmin = spaceRole === ROLE_TYPE.SuperAdmin;
    }

    affixEntryRes.showAdmin = showAdmin;
    setAffixEntry(affixEntryRes);
    setCanRemove(affixEntryRes.cancelAlonePerm);
  }


  const onVisibleChange = (v) => {
    if (v && (showOperateArrow)) {
      getCanOPerateEntry();
    }
    setVisible(v);
  };


  // 修改权限 ----查看删除 等多选等
  // 选择后立即更新权限
  const getNewPermArr = (newValue) => {
    let newPermArr = [];

    const currIsMember = (roleString === ROLE_TYPE.Member) || (roleString === ROLE_TYPE_DOC.Member);

    if (!currIsMember) { // 如果默认是管理员，会拥有所有权限值，所以要做这样的判断
      newPermArr.push(newValue);
    } else if (permValueArr.includes(newValue)) {
      // 取消选中
      newPermArr = permValueArr.filter((item) => item !== newValue);
    } else {
      newPermArr = [...permValueArr];
      newPermArr.push(newValue);
    }

    // 查看默认选中
    if (!newPermArr.includes(READ_VALUE)) {
      newPermArr.push(READ_VALUE);
    }
    return newPermArr;
  }

  // 修改权限 ----查看删除 等多选等
  // 选择后立即更新权限
  const onCheckboxGroupChange = (chooseCheckbox) => {
    const newPermArr = getNewPermArr(chooseCheckbox.value)
    const newRole = resourceType === 'dir' ? ROLE_TYPE.Member : ROLE_TYPE_DOC.Member;

    setPermValueArr(newPermArr);
    setRoleString(newRole)

    const permNumTemp = permHelperOBj.getPermissionNumber(newPermArr);


    if (isHiddenRequest) {
      onChooseCallback({ permNumTemp, role: newRole });
    } // 不需要调接口的情况，返回选择的perm和role

    if (resourceType === 'dir') {
      changeRoleForPerm({
        resourceId,
        perm: permNumTemp,
        orgMemberId,
        id,
        memberType: memberInfo.principalType,
        resourceType,
      }).then(() => {
        setSourcesData('ALONE_PERM');
        onChooseCallback({ permNum: permNumTemp, role: newRole });
        message.success(intl.t('更新权限成功'));
      });
      window.__OmegaEvent('ep_teamspace_more_permisionset_modify_ck');
    } else if (resourceType === 'doc') {
      updateCooperReq(resourceId, orgMemberId, permNumTemp, newRole).then(() => {
        setSourcesData('ALONE_PERM');
        message.destroy();
        message.success(intl.t('更新权限成功'));
        // onPermChange(username, perm);// 协作成员组件外边传来的函数
      });
    }
  };


  const onRadioGroupChange = (chooseRadio) => {
    const chooseRole = chooseRadio.value;
    if (roleString === chooseRadio.value) return;

    const permNum = permHelperOBj.getPermissionNumber(permValueArr);

    setPermValueArr([]); // 当选择管理员或者无权限时，权限应该清零吗？
    setRoleString(chooseRole)
    setVisible(false);


    if (isHiddenRequest) {
      onChooseCallback({ permNum, role: roleString });
    } // 不需要调接口的情况，返回选择的perm和role

    if (resourceType === 'dir') {
      changeRoleForRole({
        orgMemberId,
        id,
        role: chooseRole,
        resourceId,
        memberType: memberInfo.principalType,
        resourceType,
      }).then(() => {
        setSourcesData('ALONE_PERM');
        message.success(intl.t('更新权限成功'));
        onChooseCallback({ permNum, role: chooseRole });
      });
      window.__OmegaEvent('ep_teamspace_more_permisionset_admin_ck');
    } else if (resourceType === 'doc') {
      updateCooperReq(resourceId, orgMemberId, 0, chooseRole).then(() => {
        setSourcesData('ALONE_PERM');
        message.destroy();
        message.success(intl.t('更新权限成功'));
        // onPermChange(username, perm);// 协作成员组件外边传来的函数
      });
      window.__OmegaEvent('ep_docdetail_memberset_admin_ck');
    }
  }

  const onClickRemove = (type) => {
    setVisible(false);

    if (resourceType === 'dir') {
      changeRoleForRemoveUser({
        resourceId,
        orgMemberId,
        id,
      }).then(() => {
        message.success(intl.t('更新权限成功'));
        setSourcesData('');
        onChooseCallback();
        onRefresh(); // 恢复后需要从后端拿之前的权限；移除后需要删掉这个成员
      });
    } else if (resourceType === 'doc') {
      removeCooperReq(id, orgMemberId, roleString)
        .then(() => {
          // onRemoved(orgMemberId); // 协作成员组件外边传来的函数
          message.destroy();
          message.success(intl.t('更新权限成功'));
          setSourcesData('');
          onRefresh(); // 恢复后需要从后端拿之前的权限；移除后需要删掉这个成员
        })
        .catch((err) => {
          message.destroy();
          message.error(err.message);
        });
    }

    if (resourceType === 'dir') {
      window.__OmegaEvent(
        type === 'RESUME'
          ? 'ep_teamspace_more_permisionset_recover_ck'
          : 'ep_teamspace_more_permisionset_remove_ck',
      );
    } else if (resourceType === 'doc') {
      window.__OmegaEvent(
        type === 'RESUME'
          ? 'ep_docdetail_memberset_recover_ck'
          : 'ep_docdetail_memberset_remove_ck',
      );
    }
  };


  const formateOption = () => {
    const permOptionsNew = DataSetPermsDefault.map((p) => {
      if ((
        [ROLE_TYPE.Admin,
          ROLE_TYPE_DOC.Admin,
          ROLE_TYPE.NoPerm,
          ROLE_TYPE_DOC.NoPerm]
          .includes(roleString)) && p.perm === 1) {
        return { // 管理员/无权限的时候，查看可以单选
          id: p.id,
          label: p.label,
          value: p.perm,
          desc: p.desc,
          disabled: false,
          checked: false,
        }
      }
      return {
        id: p.id,
        label: p.label,
        value: p.perm,
        desc: p.desc,
        disabled: p.disabled,
        checked: false,
      }
    });

    if ((roleString === ROLE_TYPE.Member) || (roleString === ROLE_TYPE_DOC.Member)) {
      permOptionsNew.forEach((item) => {
        if (permValueArr.includes(item.value)) {
          item.checked = true;
        }
      });
    }

    const roleOptionNew = [];
    if (affixEntry.showAdmin) {
      roleOptionNew.push({
        id: resourceType === 'dir' ? ROLE_TYPE.Admin : ROLE_TYPE_DOC.Admin,
        label: intl.t('管理员'),
        desc: intl.t('可查看/编辑/上传/下载/分享给他人'),
        value: resourceType === 'dir' ? ROLE_TYPE.Admin : ROLE_TYPE_DOC.Admin,
        checked: roleString === ROLE_TYPE.Admin || roleString === ROLE_TYPE_DOC.Admin,
      });
    }

    if (!hiddenNoPermEntry && affixEntry.showNoPermMember) {
      roleOptionNew.push({
        id: resourceType === 'dir' ? ROLE_TYPE.NoPerm : ROLE_TYPE_DOC.NoPerm,
        label: intl.t('无权限成员'),
        desc: intl.t('不可访问'),
        value: resourceType === 'dir' ? ROLE_TYPE.NoPerm : ROLE_TYPE_DOC.NoPerm,
        checked: roleString === ROLE_TYPE.NoPerm || roleString === ROLE_TYPE_DOC.NoPerm,
      });
    }

    return {
      permOptionsNew,
      roleOptionNew,
    }
  }

  const _changePowerContent = () => { // 依赖permNum和roleString
    const { permOptionsNew, roleOptionNew } = formateOption();
    return <div className='dropdown-content'>
      {
        !hiddenRemoveEntry && canRemove === 'RESUME'
        && <div className='recovery'>
          <Tooltip
            placement='top'
            overlayClassName='cooperWeb-ant-tooltip__reset'
            overlayStyle={{ minWidth: 220 }}
            title={intl.t('恢复后，将与团队空间/父文件夹权限保持一致')}
          >
            <p
              className='recovery_label'
              onClick={() => onClickRemove('RESUME')}>{intl.t('该用户权限已被修改可恢复继承')}<span className='recovery_tip'>{intl.t('恢复')}</span></p>
          </Tooltip>
        </div>
      }
      {
        permOptionsNew.map((i) => {
          return <OptionItem
            key={i.value}
            optionItem={i}
            onOptionClickCallback={onCheckboxGroupChange}
            canApply={i.disabled}
         />
        })
      }
      {
        roleOptionNew.length > 0 && <div className='split-line' />
      }
      {
        roleOptionNew.length > 0 && roleOptionNew.map((i) => {
          return <OptionItem
            key={i.value}
            optionItem={i}
            onOptionClickCallback={onRadioGroupChange}
            canApply={i.disabled}
         />
        })
      }
      {
        !hiddenRemoveEntry && canRemove === 'REMOVE'
        && <div key={'REMOVE'}>
          <div className='split-line' />
          <div
            className={'option-remove'}
            key={'REMOVE'}
            onClick={() => onClickRemove('REMOVE')}>{intl.t('移除成员')}
          </div>
        </div>
      }
    </div>;
  }

  return <div className={`user-perm-select-wrap  ${showOperateArrow ? '' : 'user-perm-select-wrap-disable'} `}>
    <Popover
      visible={visible}
      onVisibleChange={onVisibleChange}
      trigger='click'
      getPopupContainer={() => document.getElementById('docs-cooperator-list')}
      overlayClassName={`${showOperateArrow ? '' : 'hide-member-perm-change'} docs-cooperator-member-popover`}
      content={showOperateArrow ? _changePowerContent() : ''}
      placement='bottomRight'>
      <div
        className={`
        ${showOperateArrow ? '' : 'choose-disabled'} 
        ${visible ? 'powerChoose-visible' : ''} 
        powerChoose-content`
      }>
        {_formatePermText()}
        <img
          className='arrow-choose'
          src={arrowImg} />
      </div>
      {
        SOURCE_MAP()[sourcesData] && SOURCE_MAP()[sourcesData] !== '' && (
          <div className="right-bottom">{intl.t(SOURCE_MAP()[sourcesData])}</div>
        )
      }
    </Popover>
  </div>
}

export default UserPermChangeNew;
